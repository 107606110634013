import React, { useState, useEffect } from 'react';
import Chart from '../../../components/Charts/Chart';
import {
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
} from '../../../Constants/ChartConstant';
import _get from 'lodash/get';
const VerticalChart = ({ data, type, location, labels }) => {
  const [state, setState] = useState({ data: [] });
  useEffect(
    () => {
      if (data) {
        setState({ ...state, data: data });
      }
    },
    [data]
  );
  let chartData = {};
  if (location === 'Count By 10 Days Alert') {
    chartData = {
      labels: ['<5', '5', '6', '7', '>7'],
      datasets: [
        {
          label: 'Count By 10 Days Alert',
          data: state.data,
          backgroundColor: [
            '#FFB07B',
            '#FFE3D1',
            '#FFEFE5',
            '#FFC59E',
            '#FD8F46'
          ],
          borderWidth: 2,
        },
      ],
    };
  } else {
    chartData = {
      labels: ['Pre Wash', 'In Wash', 'Complete'],
      datasets: [
        {
          label: 'Wash Status',
          data: state.data,
          backgroundColor: ['#48a4f4', '#5bb42c', '#f48951'],
          borderWidth: 2,
        },
      ],
    };
  }

  return (
     <>{data&&data.length!==0&& <Chart
        type={type}
        chartData={chartData}
        location={location}
        legendPosition="bottom"
      />}</>
  )
  // return<div>djk</div>
};
export default VerticalChart;
